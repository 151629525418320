import { mdiBellOutline, mdiHome, mdiMessage, mdiNewspaperVariantMultipleOutline, mdiWeb } from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'token-dashboard',
	},
	{
		title: 'Website',
		icon: mdiWeb,
		children: [
			{
				title: 'Settings',
				to: 'token-settings',
			},
		],
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'token-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'token-newsletter-list',
	},
	{
		icon: mdiBellOutline,
		title: 'notifications.notifications',
		to: 'token-notifications-list',
	},
]
