import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpJobs'

// // EDUCATION LEVELS
export const widgetsCreate = (params = '') => Http.post('/company-widget', params)
// // // LIST ALL EDUCATION LEVELS
export const widgetsList = (params = '') => Http.get(`/company-widget${serialize(params)}`)
// // // GET Widgets BY ID
export const widgetsByID = (params = '') => Http.get(`/company-widget/${params}`)
// // // UPDATE EDUCATION LEVEL
export const widgetsUpdate = (params = '') => Http.put(`/company-widget/${params.id}`, params)
// // // DELETE EDUCATION LEVEL
export const widgetsDestroy = (params = '') => Http.delete(`/company-widget/${params.id}`)
