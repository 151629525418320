import { mdiBellOutline, mdiHome, mdiMessage, mdiNewspaperVariantMultipleOutline, mdiViewListOutline } from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'shop-dashboard',
	},
	{
		title: 'categories',
		icon: mdiViewListOutline,
		children: [
			{
				title: 'categories',
				to: 'shop-categories-list',
			},
			{
				title: 'subCategories',
				to: 'shop-subcategories-list',
			},
		],
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'shop-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'shop-newsletter-list',
	},
	{
		icon: mdiBellOutline,
		title: 'notifications.notifications',
		to: 'shop-notifications-list',
	},
]
