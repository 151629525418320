import { widgetsByID, widgetsCreate, widgetsDestroy, widgetsList, widgetsUpdate } from '@/services/jobs/widgets.service'

export default {
	namespaced: true,
	state: {
		// WIDGETS
		widgetsList: {},
		widgets: {},
		// STATUS WIDGETS
		widgetsStatus: 'pending',
		widgetsSaveStatus: 'init',
		widgetsDeleteStatus: 'init',
	},
	getters: {
		// WIDGETS
		getWidgetsList(state) {
			return state.widgetsList
		},
		getWidgets(state) {
			return state.widgets
		},
		// WIDGETS
		getWidgetsDeleteStatus(state) {
			return state.widgetsDeleteStatus
		},
		getWidgetsSaveStatus(state) {
			return state.widgetsSaveStatus
		},
		getWidgetsStatus(state) {
			return state.widgetsStatus
		},
	},
	mutations: {
		// WIDGETS
		WIDGETS_LIST(state, value) {
			state.widgetsList = value
		},
		SET_WIDGETS(state, value) {
			state.widgets = value
		},
		// STATUS WIDGETS
		WIDGETS_STATUS(state, value) {
			state.widgetsStatus = value
		},
		WIDGETS_SAVE_STATUS(state, value) {
			state.widgetsSaveStatus = value
		},
		WIDGETS_DELETE_STATUS(state, value) {
			state.widgetsDeleteStatus = value
		},
	},
	actions: {
		// Widgets
		async fetchWidgetsList({ commit }, params) {
			console.log('params', params)
			commit('WIDGETS_STATUS', 'pending')
			commit('WIDGETS_LIST', {})
			try {
				const { data } = await widgetsList(params)
				console.log(data)
				commit('WIDGETS_STATUS', 'init')
				commit('WIDGETS_LIST', data)
				return data
			} catch (error) {
				commit('WIDGETS_STATUS', 'init')
				throw error
			}
		},
		async fetchWidgetsByID({ commit }, params) {
			commit('WIDGETS_STATUS', 'pending')
			try {
				const { data } = await widgetsByID(params)
				commit('WIDGETS_STATUS', 'init')
				commit('SET_WIDGETS', data.data)
				return data
			} catch (error) {
				commit('WIDGETS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewWidgets({ commit }, params) {
			commit('WIDGETS_SAVE_STATUS', 'pending')
			try {
				const { data } = await widgetsCreate(params)
				commit('WIDGETS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WIDGETS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWidgets({ commit }, params) {
			commit('WIDGETS_SAVE_STATUS', 'pending')
			try {
				const { data } = await widgetsUpdate(params)
				commit('WIDGETS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WIDGETS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWidgetsByID({ commit }, params) {
			commit('WIDGETS_DELETE_STATUS', 'pending')
			try {
				const { data } = await widgetsDestroy(params)
				commit('WIDGETS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WIDGETS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
