import categories from './categories'
import subcategories from './subcategories'

const shop = [
	...categories,
	...subcategories,
	{
		path: '/shop/dashboard',
		name: 'shop-dashboard',
		component: () => import('@/views/shop/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/shop/categories',
		name: 'shop-categories',
		component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/shop/sub-categories',
		name: 'shop-sub-categories',
		component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/shop/messages',
		name: 'shop-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/shop/newsletter',
		name: 'shop-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/shop/notifications',
		name: 'shop-notifications-list',
		component: () => import('@/views/notifications/Notifications.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default shop
