import {
	widgetformatByID,
	widgetformatCreate,
	widgetformatDestroy,
	widgetformatsList,
	widgetformatUpdate,
} from '@/services/jobs/widgetFormats.service'

export default {
	namespaced: true,
	state: {
		// WIDGETFORMATS
		widgetformatsList: {},
		widgetformat: {},
		// STATUS WIDGETFORMAT
		widgetformatsStatus: 'pending',
		widgetformatsSaveStatus: 'init',
		widgetformatsDeleteStatus: 'init',
	},
	getters: {
		// WIDGETFORMAT
		getWidgetFormatsList(state) {
			return state.widgetformatsList
		},
		getWidgetFormat(state) {
			return state.widgetformat
		},
		// WIDGETFORMAT
		getWidgetFormatsDeleteStatus(state) {
			return state.widgetformatsDeleteStatus
		},
		getWidgetFormatsSaveStatus(state) {
			return state.widgetformatsSaveStatus
		},
		getWidgetFormatsStatus(state) {
			return state.widgetformatsStatus
		},
	},
	mutations: {
		// WIDGETFORMATS
		WIDGETFORMATS_LIST(state, value) {
			state.widgetformatsList = value
		},
		SET_WIDGETFORMAT(state, value) {
			state.widgetformat = value
		},
		// STATUS WIDGETFORMATS
		WIDGETFORMATS_STATUS(state, value) {
			state.widgetformatsStatus = value
		},
		WIDGETFORMAT_SAVE_STATUS(state, value) {
			state.widgetformatsSaveStatus = value
		},
		WIDGETFORMAT_DELETE_STATUS(state, value) {
			state.widgetformatsDeleteStatus = value
		},
	},
	actions: {
		// WidgetFormats
		async fetchWidgetFormatList({ commit }, params) {
			console.log('params', params)
			commit('WIDGETFORMATS_STATUS', 'pending')
			commit('WIDGETFORMATS_LIST', {})
			try {
				const { data } = await widgetformatsList(params)
				console.log(data)
				commit('WIDGETFORMATS_STATUS', 'init')
				commit('WIDGETFORMATS_LIST', data)
				return data
			} catch (error) {
				commit('WIDGETFORMATS_STATUS', 'init')
				throw error
			}
		},
		async fetchWidgetFormatByID({ commit }, params) {
			commit('WIDGETFORMATS_STATUS', 'pending')
			try {
				const { data } = await widgetformatByID(params)
				commit('WIDGETFORMATS_STATUS', 'init')
				commit('SET_WIDGETFORMAT', data.data)
				return data
			} catch (error) {
				commit('WIDGETFORMATS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewWidgetFormat({ commit }, params) {
			commit('WIDGETFORMAT_SAVE_STATUS', 'pending')
			try {
				const { data } = await widgetformatCreate(params)
				commit('WIDGETFORMAT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WIDGETFORMAT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWidgetFormat({ commit }, params) {
			commit('WIDGETFORMAT_SAVE_STATUS', 'pending')
			try {
				const { data } = await widgetformatUpdate(params)
				commit('WIDGETFORMAT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WIDGETFORMAT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWidgetFormatByID({ commit }, params) {
			commit('WIDGETFORMAT_DELETE_STATUS', 'pending')
			try {
				const { data } = await widgetformatDestroy(params)
				commit('WIDGETFORMAT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WIDGETFORMAT_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
