import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // EDUCATION LEVELS
export const widgetformatCreate = (params = '') => Http.post('/widget-format', params)
// // // LIST ALL EDUCATION LEVELS
export const widgetformatsList = (params = '') => Http.get(`/widget-format${serialize(params)}`)

// // // GET WidgetFormat BY ID
export const widgetformatByID = (params = '') => Http.get(`/widget-format/${params}`)
// // // UPDATE EDUCATION LEVEL
export const widgetformatUpdate = (params = '') => Http.put(`/widget-format/${params.id}`, params)
// // // DELETE EDUCATION LEVEL
export const widgetformatDestroy = (params = '') => Http.delete(`/widget-format/${params.id}`)
