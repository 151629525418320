import {
	mdiAccountVoice,
	mdiBellOutline,
	mdiGamepadSquare,
	mdiHammerWrench,
	mdiHome,
	mdiImageAlbum,
	mdiKeyVariant,
	mdiMessage,
	mdiNewspaperVariantMultipleOutline,
	mdiPaletteOutline,
	mdiViewCompact,
} from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'agency-dashboard',
	},
	{
		title: 'Marketing',
		icon: mdiViewCompact,
		children: [
			{
				title: 'Community',
				children: [
					{
						title: 'menu.agency.marketing.highlights',
						to: 'agency-marketing-highlights-list',
					},
					{
						title: 'menu.agency.marketing.highlightBannersApp',
						to: 'agency-marketing-highlight-banners-list',
					},
					/* {
							title: 'menu.agency.marketing.highlightBannersWeb',
							to: '#',
						}, */
				],
			},
			/* {
				title: 'Jobs',
				children: [
					{
						title: '#',
						to: '#',
					}
				],
			}, */
			{
				title: 'TV',
				children: [
					{
						title: 'menu.agency.marketing.tvBanners',
						to: 'agency-marketing-tv-banners-list',
					},
				],
			},
			{
				title: 'menu.agency.marketing.providers',
				to: 'agency-marketing-providers-list',
			},
			{
				title: 'menu.agency.marketing.campaigns',
				to: 'agency-marketing-campaigns-list',
			},
			{
				title: 'menu.agency.marketing.presentations',
				to: 'agency-marketing-presentations-list',
			},
		],
	},
	{
		title: 'menu.magazines.title',
		icon: mdiImageAlbum,
		children: [
			{
				title: 'menu.magazines.title',
				to: 'agency-magazine-list',
			},
			{
				title: 'menu.magazines.sections',
				to: 'agency-magazine-sections-list',
			},
		],
	},
	{
		title: 'Mundo Indie',
		icon: mdiGamepadSquare,
		children: [
			{
				title: 'menu.agency.goals',
				to: 'agency-goals-list',
			},
			{
				title: 'menu.agency.projects',
				to: 'agency-projects-list',
			},
			{
				title: 'menu.agency.game',
				to: 'agency-game-status-list',
			},
			{
				title: 'menu.agency.testimonials',
				to: 'agency-testimonials-list',
			},
			{
				title: 'menu.agency.workflow',
				to: 'agency-workflow-status-list',
			},
		],
	},
	{
		title: 'menu.agency.keys',
		icon: mdiKeyVariant,
		children: [
			{
				title: 'menu.agency.keys',
				to: 'agency-keys-list',
			},
			{
				title: 'menu.agency.keysContents',
				to: 'agency-keys-contents-list',
			},
			{
				title: 'menu.agency.keysTypes',
				to: 'agency-keys-types-list',
			},
			{
				title: 'menu.agency.keysStatus',
				to: 'agency-keys-status-list',
			},
			{
				title: 'menu.agency.keysSuppliers',
				to: 'agency-keys-suppliers-list',
			},
			{
				title: 'menu.agency.keysSuppliersPrs',
				to: 'agency-keys-suppliers-prs-list',
			},
		],
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'agency-messages-list',
	},
	{
		icon: mdiAccountVoice,
		title: 'menu.agency.contactMessages',
		to: 'contact-requests-list',
	},
	{
		title: 'Newsletter',
		icon: mdiNewspaperVariantMultipleOutline,
		children: [
			{
				title: 'Newsletter',
				to: 'agency-newsletter-list',
			},
			{
				title: 'menu.agency.newsletter.campaigns',
				to: 'agency-newsletter-campaigns-list',
			},
			{
				title: 'menu.agency.newsletter.categories',
				to: 'agency-newsletter-categories-list',
			},
			{
				title: 'menu.agency.newsletter.periodicities',
				to: 'agency-newsletter-periodicities-list',
			},
		],
	},
	{
		title: 'menu.agency.services',
		icon: mdiHammerWrench,
		children: [
			{
				title: 'menu.agency.services',
				to: 'agency-services-list',
			},
			{
				title: 'menu.agency.categories',
				to: 'services-categories-list',
			},
			{
				title: 'menu.agency.audience',
				to: 'agency-target-audiences-list',
			},
		],
	},
	{
		title: 'menu.themes.themes',
		icon: mdiPaletteOutline,
		children: [
			{
				title: 'menu.themes.appThemes',
				to: 'app-themes-list',
			},
			{
				title: 'menu.themes.projectsVariables',
				to: 'projects-variables-list',
			},
		],
	},
	{
		icon: mdiBellOutline,
		title: 'notifications.notifications',
		to: 'agency-notifications-list',
	},
]
