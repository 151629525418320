import {
	contentByID,
	contentCreate,
	contentDestroy,
	contentUpdate,
	contentsList,
	deleteKeysByID,
	itemByID,
	itemCreate,
	itemDestroy,
	itemUpdate,
	itemsList,
	keyList,
	keysByID,
	newKeys,
	prsByID,
	prsCreate,
	prsDestroy,
	prsList,
	prsUpdate,
	statusByID,
	statusCreate,
	statusDestroy,
	statusList,
	statusUpdate,
	supplierByID,
	supplierCreate,
	supplierDestroy,
	supplierUpdate,
	suppliersList,
	typeByID,
	typeCreate,
	typeDestroy,
	typeUpdate,
	typesList,
	updateKeys,
} from '@/services/agency/keys.service'

export default {
	namespaced: true,
	state: {
		// KEYs
		keysList: {},
		key: {},

		// STATUS
		keyStatus: 'pending',
		keySaveStatus: 'init',
		keyDeleteStatus: 'init',

		// Keys TYPES
		typesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		type: {},

		// STATUS Keys TYPES
		typesStatus: 'pending',
		typesSaveStatus: 'init',
		typesDeleteStatus: 'init',

		// Keys CONTENTS
		contentsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		content: {},

		// STATUS Keys CONTENTS
		contentsStatus: 'pending',
		contentsSaveStatus: 'init',
		contentsDeleteStatus: 'init',

		// Keys STATUS
		statusList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		status: {},

		// STATUS Keys STATUS
		statusestatus: 'pending',
		statusesaveStatus: 'init',
		statusDeleteStatus: 'init',

		// Keys SUPPLIERS
		suppliersList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		supplier: {},

		// STATUS Keys SUPPLIERS
		suppliersStatus: 'pending',
		suppliersSaveStatus: 'init',
		suppliersDeleteStatus: 'init',

		// Keys SUPPLIERS PRS
		prsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		prs: {},

		// STATUS Keys SUPPLIERS PRS
		prsStatus: 'pending',
		prsSaveStatus: 'init',
		prsDeleteStatus: 'init',

		// Keys ITEMS
		itemsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		item: {},

		// STATUS Keys ITEMS
		itemsStatus: 'pending',
		itemsSaveStatus: 'init',
		itemsDeleteStatus: 'init',
	},
	getters: {
		// KEYS
		getKeysList(state) {
			return state.keysList
		},
		// STATUS
		getKeyDeleteStatus(state) {
			return state.keyDeleteStatus
		},
		getKeySaveStatus(state) {
			return state.keySaveStatus
		},
		getKeyStatus(state) {
			return state.keyStatus
		},

		// Types Keys
		getTypesList(state) {
			return state.typesList
		},
		getType(state) {
			return state.type
		},

		// Keys TYPES
		getTypesDeleteStatus(state) {
			return state.typesDeleteStatus
		},
		getTypesSaveStatus(state) {
			return state.typesSaveStatus
		},
		getTypesStatus(state) {
			return state.typesStatus
		},

		// Contents Keys
		getContentsList(state) {
			return state.contentsList
		},
		getContent(state) {
			return state.content
		},

		// Keys CONTENTS
		getContentsDeleteStatus(state) {
			return state.contentsDeleteStatus
		},
		getContentsSaveStatus(state) {
			return state.contentsSaveStatus
		},
		getContentsStatus(state) {
			return state.contentsStatus
		},

		// Keys STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// Keys STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// Keys STATUS
		getStatusDeleteStatus(state) {
			return state.statusDeleteStatus
		},
		getStatusesaveStatus(state) {
			return state.statusesaveStatus
		},
		getStatusestatus(state) {
			return state.statusestatus
		},

		// Suppliers Keys
		getSuppliersList(state) {
			return state.suppliersList
		},
		getSupplier(state) {
			return state.supplier
		},

		// Keys SUPPLIERS
		getSuppliersDeleteStatus(state) {
			return state.suppliersDeleteStatus
		},
		getSuppliersSaveStatus(state) {
			return state.suppliersSaveStatus
		},
		getSuppliersStatus(state) {
			return state.suppliersStatus
		},

		// SUPPLIERS Keys PRS
		getPrsList(state) {
			return state.prsList
		},
		getPrs(state) {
			return state.prs
		},

		// SUPPLIERS Keys PRS
		getPrsDeleteStatus(state) {
			return state.prsDeleteStatus
		},
		getPrsSaveStatus(state) {
			return state.prsSaveStatus
		},
		getPrsStatus(state) {
			return state.prsStatus
		},

		// Items Keys
		getItemsList(state) {
			return state.itemsList
		},
		getItem(state) {
			return state.item
		},

		// Keys ITEMS
		getItemsDeleteStatus(state) {
			return state.itemsDeleteStatus
		},
		getItemsSaveStatus(state) {
			return state.itemsSaveStatus
		},
		getItemsStatus(state) {
			return state.itemsStatus
		},
	},
	mutations: {
		// KEYS
		SET_KEYS_LIST(state, value) {
			state.keysList = value
		},
		SET_KEY(state, value) {
			state.key = value
		},

		// STATUS
		KEY_STATUS(state, value) {
			state.keyStatus = value
		},
		KEY_SAVE_STATUS(state, value) {
			state.keySaveStatus = value
		},
		KEY_DELETE_STATUS(state, value) {
			state.keyDeleteStatus = value
		},

		// Keys TYPES
		TYPES_LIST(state, value) {
			state.typesList = value
		},
		SET_TYPE(state, value) {
			state.type = value
		},

		// STATUS Keys TYPES
		TYPES_STATUS(state, value) {
			state.typesStatus = value
		},
		TYPE_SAVE_STATUS(state, value) {
			state.typesSaveStatus = value
		},
		TYPE_DELETE_STATUS(state, value) {
			state.typesDeleteStatus = value
		},

		// Keys CONTENTS
		CONTENTS_LIST(state, value) {
			state.contentsList = value
		},
		SET_CONTENT(state, value) {
			state.content = value
		},

		// STATUS Keys CONTENTS
		CONTENTS_STATUS(state, value) {
			state.contentsStatus = value
		},
		CONTENT_SAVE_STATUS(state, value) {
			state.contentsSaveStatus = value
		},
		CONTENT_DELETE_STATUS(state, value) {
			state.contentsDeleteStatus = value
		},

		// Keys STATUS
		STATUS_LIST(state, value) {
			state.statusList = value
		},
		SET_STATUS(state, value) {
			state.status = value
		},

		// STATUS Keys STATUS
		STATUS_STATUS(state, value) {
			state.statusestatus = value
		},
		STATUS_SAVE_STATUS(state, value) {
			state.statusesaveStatus = value
		},
		STATUS_DELETE_STATUS(state, value) {
			state.statusDeleteStatus = value
		},

		// Keys SUPPLIERS
		SUPPLIERS_LIST(state, value) {
			state.suppliersList = value
		},
		SET_SUPPLIER(state, value) {
			state.supplier = value
		},

		// STATUS Keys SUPPLIERS
		SUPPLIERS_STATUS(state, value) {
			state.suppliersStatus = value
		},
		SUPPLIER_SAVE_STATUS(state, value) {
			state.suppliersSaveStatus = value
		},
		SUPPLIER_DELETE_STATUS(state, value) {
			state.suppliersDeleteStatus = value
		},

		// Keys SUPPLIERS PRS
		PRS_LIST(state, value) {
			state.prsList = value
		},
		SET_PRS(state, value) {
			state.prs = value
		},

		// STATUS Keys SUPPLIERS PRS
		PRS_STATUS(state, value) {
			state.prsStatus = value
		},
		PRS_SAVE_STATUS(state, value) {
			state.prsSaveStatus = value
		},
		PRS_DELETE_STATUS(state, value) {
			state.prsDeleteStatus = value
		},

		// Keys ITEMS
		ITEMS_LIST(state, value) {
			state.itemsList = value
		},
		SET_ITEM(state, value) {
			state.item = value
		},

		// STATUS Keys ITEMS
		ITEMS_STATUS(state, value) {
			state.itemsStatus = value
		},
		ITEM_SAVE_STATUS(state, value) {
			state.itemsSaveStatus = value
		},
		ITEM_DELETE_STATUS(state, value) {
			state.itemsDeleteStatus = value
		},
	},
	actions: {
		// KEYS
		async fetchKeysList({ commit }, params) {
			commit('KEY_STATUS', 'pending')
			try {
				const { data } = await keyList(params)
				commit('KEY_STATUS', 'init')
				commit('SET_KEYS_LIST', data)
				return data
			} catch (error) {
				commit('KEY_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchKeysByID({ state, commit }, params) {
			try {
				const { data } = await keysByID(params)
				commit('SET_KEY', data.data)
				return data
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewKeys({ commit }, params) {
			commit('KEY_SAVE_STATUS', 'pending')
			try {
				const { data } = await newKeys(params)
				commit('KEY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('KEY_SAVE_STATUS', 'init')
				throw error
			}
		},

		async updateKeys({ commit }, params) {
			commit('KEY_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateKeys(params)
				commit('KEY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('KEY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteKeysByID({ state, commit }, params) {
			commit('KEY_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteKeysByID(params)
				commit('KEY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('KEY_DELETE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Keys Items
		async fetchItemList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('ITEMS_STATUS', 'pending')
			commit('ITEMS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await itemsList(params)
				commit('ITEMS_STATUS', 'init')
				commit('ITEMS_LIST', data)
				return data
			} catch (error) {
				commit('ITEMS_STATUS', 'init')
				throw error
			}
		},
		async fetchItemByID({ commit }, params) {
			commit('ITEMS_STATUS', 'pending')
			try {
				const { data } = await itemByID(params)
				commit('ITEMS_STATUS', 'init')
				commit('SET_ITEM', data.data)
				return data
			} catch (error) {
				commit('ITEMS_STATUS', 'init')

				return error
			}
		},
		async saveNewItem({ commit }, params) {
			commit('ITEM_SAVE_STATUS', 'pending')
			try {
				const { data } = await itemCreate(params)
				commit('ITEM_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ITEM_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateItem({ commit }, params) {
			commit('ITEM_SAVE_STATUS', 'pending')
			try {
				const { data } = await itemUpdate(params)
				commit('ITEM_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ITEM_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteItemByID({ commit }, params) {
			commit('ITEM_DELETE_STATUS', 'pending')
			try {
				const { data } = await itemDestroy(params)
				commit('ITEM_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ITEM_DELETE_STATUS', 'error')

				return error
			}
		},

		// Keys Types
		async fetchTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TYPES_STATUS', 'pending')
			commit('TYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await typesList(params)
				commit('TYPES_STATUS', 'init')
				commit('TYPES_LIST', data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchTypeByID({ commit }, params) {
			commit('TYPES_STATUS', 'pending')
			try {
				const { data } = await typeByID(params)
				commit('TYPES_STATUS', 'init')
				commit('SET_TYPE', data.data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeCreate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeUpdate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTypeByID({ commit }, params) {
			commit('TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await typeDestroy(params)
				commit('TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Keys Contents
		async fetchContentList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CONTENTS_STATUS', 'pending')
			commit('CONTENTS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await contentsList(params)
				commit('CONTENTS_STATUS', 'init')
				commit('CONTENTS_LIST', data)
				return data
			} catch (error) {
				commit('CONTENTS_STATUS', 'init')
				throw error
			}
		},
		async fetchContentByID({ commit }, params) {
			commit('CONTENTS_STATUS', 'pending')
			try {
				const { data } = await contentByID(params)
				commit('CONTENTS_STATUS', 'init')
				commit('SET_CONTENT', data.data)
				return data
			} catch (error) {
				commit('CONTENTS_STATUS', 'init')

				return error
			}
		},
		async saveNewContent({ commit }, params) {
			commit('CONTENT_SAVE_STATUS', 'pending')
			try {
				const { data } = await contentCreate(params)
				commit('CONTENT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CONTENT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateContent({ commit }, params) {
			commit('CONTENT_SAVE_STATUS', 'pending')
			try {
				const { data } = await contentUpdate(params)
				commit('CONTENT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CONTENT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteContentByID({ commit }, params) {
			commit('CONTENT_DELETE_STATUS', 'pending')
			try {
				const { data } = await contentDestroy(params)
				commit('CONTENT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CONTENT_DELETE_STATUS', 'error')

				return error
			}
		},

		// Keys Status
		async fetchStatusList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('STATUS_STATUS', 'pending')
			commit('STATUS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await statusList(params)
				commit('STATUS_STATUS', 'init')
				commit('STATUS_LIST', data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')
				throw error
			}
		},
		async fetchStatusByID({ commit }, params) {
			commit('STATUS_STATUS', 'pending')
			try {
				const { data } = await statusByID(params)
				commit('STATUS_STATUS', 'init')
				commit('SET_STATUS', data.data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')

				return error
			}
		},
		async saveNewStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusCreate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusUpdate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteStatusByID({ commit }, params) {
			commit('STATUS_DELETE_STATUS', 'pending')
			try {
				const { data } = await statusDestroy(params)
				commit('STATUS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_DELETE_STATUS', 'error')

				return error
			}
		},

		// Keys Suppliers
		async fetchSupplierList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SUPPLIERS_STATUS', 'pending')
			commit('SUPPLIERS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await suppliersList(params)
				commit('SUPPLIERS_STATUS', 'init')
				commit('SUPPLIERS_LIST', data)
				return data
			} catch (error) {
				commit('SUPPLIERS_STATUS', 'init')
				throw error
			}
		},
		async fetchSupplierByID({ commit }, params) {
			commit('SUPPLIERS_STATUS', 'pending')
			try {
				const { data } = await supplierByID(params)
				commit('SUPPLIERS_STATUS', 'init')
				commit('SET_SUPPLIER', data.data)
				return data
			} catch (error) {
				commit('SUPPLIERS_STATUS', 'init')

				return error
			}
		},
		async saveNewSupplier({ commit }, params) {
			commit('SUPPLIER_SAVE_STATUS', 'pending')
			try {
				const { data } = await supplierCreate(params)
				commit('SUPPLIER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SUPPLIER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateSupplier({ commit }, params) {
			commit('SUPPLIER_SAVE_STATUS', 'pending')
			try {
				const { data } = await supplierUpdate(params)
				commit('SUPPLIER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SUPPLIER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteSupplierByID({ commit }, params) {
			commit('SUPPLIER_DELETE_STATUS', 'pending')
			try {
				const { data } = await supplierDestroy(params)
				commit('SUPPLIER_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SUPPLIER_DELETE_STATUS', 'error')

				return error
			}
		},

		// Keys Suppliers PRS
		async fetchPrsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PRS_STATUS', 'pending')
			commit('PRS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await prsList(params)
				commit('PRS_STATUS', 'init')
				commit('PRS_LIST', data)
				return data
			} catch (error) {
				commit('PRS_STATUS', 'init')
				throw error
			}
		},
		async fetchPrsByID({ commit }, params) {
			commit('PRS_STATUS', 'pending')
			try {
				const { data } = await prsByID(params)
				commit('PRS_STATUS', 'init')
				commit('SET_PRS', data.data)
				return data
			} catch (error) {
				commit('PRS_STATUS', 'init')

				return error
			}
		},
		async saveNewPrs({ commit }, params) {
			commit('PRS_SAVE_STATUS', 'pending')
			try {
				const { data } = await prsCreate(params)
				commit('PRS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updatePrs({ commit }, params) {
			commit('PRS_SAVE_STATUS', 'pending')
			try {
				const { data } = await prsUpdate(params)
				commit('PRS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deletePrsByID({ commit }, params) {
			commit('PRS_DELETE_STATUS', 'pending')
			try {
				const { data } = await prsDestroy(params)
				commit('PRS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRS_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
