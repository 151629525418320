<template>
	<v-menu
		:close-on-content-click="false"
		offset-y
		left
		nudge-bottom="22"
		:elevation="$vuetify.theme.dark ? 9 : 8"
		content-class="list-style notification-menu-content"
	>
	<template v-slot:activator="{ on, attrs }">
		<!-- Quando não há notificações (total = 0) -->
		<div v-if="total === 0">
			<v-icon v-bind="attrs" v-on="on">
			{{ icons.mdiBellOutline }}
			</v-icon>
		</div>

		<!-- Quando há notificações (total > 0) -->
		<div v-else>
			<!-- A prop :content exibe o número no badge -->
			<v-badge
			top
			color="primary"
			overlap
			offset-x="10"
			offset-y="10"
			:content="total"
			>
			<v-icon v-bind="attrs" v-on="on">
				{{ icons.mdiBellOutline }}
			</v-icon>
			</v-badge>
		</div>
	</template>

		<v-card class="app-bar-notification-content-container">
			<perfect-scrollbar class="ps-user-notifications" :options="perfectScrollbarOptions" @scroll="handleScroll">
				<v-list class="py-0">
					<!-- Header -->

					<v-list-item class="d-flex" link>
						<div class="d-flex align-center justify-space-between flex-grow-1">
							<span class="font-weight-semibold">{{ $t('notifications.notifications') }}</span>

							<div
								key="read-all-btn"
								class="read-all-btn-list-item small"
								block
								:disabled="notificationStatus === 'pending'"
								color="primary"
								@click="markNotificationRealAll"
							>
								{{ $t('notifications.allRead') }}
							</div>

							<v-chip class="primary--text font-weight-semibold" small>
								{{ total }} {{ $t(total === 1 ? 'notifications.new' : 'notifications.news') }}
							</v-chip>
						</div>
					</v-list-item>
					<v-divider />

					<div v-if="notifications">
						<!-- Notifications -->
						<template v-for="(notification, index) in notifications.data">
							<v-list-item
								:key="notification.id"
								:class="{ 'notification-read': notification.read_at !== null }"
								link
								@click="markNotificationRead(notification)"
							>
								<!-- Avatar -->
								<v-list-item-avatar
									:class="[
										{
											'v-avatar-light-bg primary--text justify-center':
												notification.user && !notification.user.avatar,
										},
									]"
									size="38"
								>
									<v-img v-if="notification.data.image" :src="notification.data.image" />
								</v-list-item-avatar>

								<!-- Content -->
								<v-list-item-content class="d-block">
									<v-list-item-title class="text-sm font-weight-semibold">
										{{ getTitle(notification) }}
									</v-list-item-title>
									<v-list-item-subtitle class="text-sm">
										{{ getMessage(notification) }}
									</v-list-item-subtitle>
								</v-list-item-content>

								<!-- Item Action/Time -->
								<v-list-item-action>
									<span class="text--secondary text-xs">{{ notification.created_at_diff }}</span>
								</v-list-item-action>
							</v-list-item>
							<v-divider :key="index"></v-divider>
						</template>
					</div>

					<!-- <v-list-item key="read-all-btn" class="read-all-btn-list-item">
						<v-btn block :disabled="notificationStatus === 'pending'" color="primary" @click="markNotificationRealAll">
							{{ $t('notifications.allRead') }}
						</v-btn>
						<v-progress-circular indeterminate color="green" />
					</v-list-item> -->

					<v-list-item v-if="notificationStatus === 'pending'">
						<v-progress-circular indeterminate color="green" />
					</v-list-item>
				</v-list>
			</perfect-scrollbar>
			<v-list-item v-if="notifications && notifications.data.length" style="justify-content: center">
				<router-link to="/mundo-gamer-platforms/notifications">
					<v-btn text>
						{{ $t('notifications.seeAll') }}
					</v-btn>
				</router-link>
			</v-list-item>
		</v-card>
	</v-menu>
</template>

<script>
import { generateAdminRoute } from '@/utils/adminUrlGenerator'
import { getInitialName } from '@core/utils'
import { mdiBellOutline } from '@mdi/js'

// 3rd Party
import _ from 'lodash'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { mapActions, mapGetters } from 'vuex'

export default {
	components: {
		PerfectScrollbar,
	},
	setup() {
		const perfectScrollbarOptions = {
			maxScrollbarLength: 60,
			wheelPropagation: false,
		}

		return {
			getInitialName,
			perfectScrollbarOptions,
			icons: {
				mdiBellOutline,
			},
		}
	},
	data() {
		return {
			notifications: null,
			total: 0,
			wss: null,
		}
	},
	computed: {
		...mapGetters({
			notificationsData: 'main/notifications/getNotificationList',
			notificationStatus: 'main/notifications/getNotificationStatus',
			notificationTotalLatest: 'main/notifications/getNotificationTotalLatest',
		}),
		adminBaseUrl() {
			return process.env.VUE_APP_ADMIN_BASE_URL || 'http://localhost:8080'
		},
	},
	async mounted() {
		this.connect()

		setInterval(() => {
			if (this.wss.readyState === 3) {
				this.wss = null
				this.connect()
			}
		}, 90000)
	},
	methods: {
		...mapActions({
			fetchNotifications: 'main/notifications/fetchNotifications',
			fetchNotificationsTotalLatest: 'main/notifications/fetchNotificationsTotalLatest',
			markRead: 'main/notifications/markNotificationRead',
			markReadAll: 'main/notifications/markNotificationsReadAll',
		}),
		async handleScroll({ target }) {
			const isBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - 10

			if (isBottom && this.notificationStatus === 'init') {
				this.notificationStatus = 'loading'

				try {
					const nextPage = this.notifications.meta.pagination.current_page + 1
					if (nextPage <= this.notifications.meta.pagination.total_pages) {
						await this.loadMore()
					}
				} catch (error) {
					console.error('Erro ao carregar mais notificações:', error)
				} finally {
					this.notificationStatus = 'init'
				}
			}
		},
		async loadMore() {
			const nextPage = this.notifications.meta.pagination.current_page + 1
			await this.fetchNotifications({
				sort: 'created_at',
				order: 'desc',
				per_page: 5,
				page: nextPage,
			})
			this.notifications.data = this.notifications.data.concat(this.notificationsData.data)
			this.notifications.meta = this.notificationsData.meta
		},
		async markNotificationRead(notification) {
			await this.markRead({ notificationId: notification.id })

			const { object, id } = notification.data
			const route = generateAdminRoute(object, id)

			if (route) {
				const fullUrl = `${this.adminBaseUrl}${route}`
				if (fullUrl.startsWith('http')) {
					window.location.href = fullUrl
				} else {
					this.$router.push(fullUrl)
				}
			} else {
				console.error('Falha ao gerar a URL:', notification)
			}

			await this.fetchNotifications({ sort: 'created_at', order: 'desc', per_page: 5, page: 1 })
			await this.fetchNotificationsTotalLatest({ 'filter[read]': 0, total: 1 })
			this.notifications = this.notificationsData
			this.total = this.notificationTotalLatest.total
		},
		async markNotificationRealAll() {
			try {
				this.notificationStatus = 'pending'

				await this.markReadAll()

				const [notificationsResponse, totalResponse] = await Promise.all([
					this.fetchNotifications({ sort: 'created_at', order: 'desc', per_page: 5, page: 1 }),
					this.fetchNotificationsTotalLatest({ 'filter[read]': 0, total: 1 }),
				])

				this.notifications = this.notificationsData
				this.total = this.notificationTotalLatest.total
			} catch (error) {
				console.error('Erro ao marcar todas as notificações como lidas:', error)
			} finally {
				this.notificationStatus = 'init'
			}
		},
		async connect() {
			const user = this.$authService.getUser()
			this.wss = new WebSocket(`wss://${process.env.VUE_APP_PUSHER_HOST}/app/main-key?user_id=${user.uuid}`)

			this.wss.onmessage = async () => {
				await this.getNotifications()
			}
			this.wss.onerror = error => {
				console.error('WebSocket error:', error)
			}
		},
		async getNotifications() {
			this.notifications = null
			this.total = 0
			await this.fetchNotifications({ sort: 'created_at', order: 'desc', per_page: 5, page: 1 })
			await this.fetchNotificationsTotalLatest({ 'filter[read]': 0, total: 1 })
			this.notifications = this.notificationsData
			this.total = this.notificationTotalLatest.total
		},
		getTitle(notification) {
			const title = _.get(notification, 'data.title', '')
			return _.truncate(title, {
				length: 20,
				omission: '...',
			})
		},
		getMessage(notification) {
			const message = _.get(notification, 'data.message', '')
			return _.truncate(message, {
				length: 20,
				omission: '...',
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.notification-read {
	opacity: 0.6;
}

.app-bar-notification-content-container {
	.read-all-btn-list-item {
		font-size: 10px;
		padding: 2px 8px;
		margin: 0 2px;
		border-radius: 10px;
		cursor: pointer;
		color: #fff;
		background-color: #5a595991;
		&:hover {
			background-color: var(--v-primary-base);
		}
	}
}

.ps-user-notifications {
	max-height: 400px;
	overflow-y: auto;
}

.ps {
	overflow: scroll !important;
}

.notification-menu-content {
	@media #{map-get($display-breakpoints, 'xs-only')} {
		min-width: calc(100vw - (1.5rem * 2)) !important;
		left: 50% !important;
		transform: translateX(-50%);
	}
}

.v-progress-circular {
	width: 100% !important;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(166, 166, 166, 0);
	border-radius: 0px 0px 0px 0px;
	background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: gray;
	border-radius: 0px 0px 0px 0px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ee3831;
}
</style>
