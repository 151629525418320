import courses from '../academy/courses'

const academy = [
	...courses,
	{
		path: '/academy/dashboard',
		name: 'academy-dashboard',
		component: () => import('@/views/academy/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/services',
		name: 'academy-services',
		component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/institutions',
		name: 'institutions',
		component: () => import('@/views/institutions/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/messages',
		name: 'academy-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/newsletter',
		name: 'academy-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/notifications',
		name: 'academy-notifications-list',
		component: () => import('@/views/notifications/Notifications.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default academy
