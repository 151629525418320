import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/http'

//LIST OF ALL Keys Types
export const typesList = (params = '') => Http.get(`/key-types${serialize(params)}`)
export const typeCreate = (params = '') => Http.post('/key-types', params)
export const typeByID = (params = '') => Http.get(`/key-types/${params}`)
export const typeUpdate = (params = '') => Http.put(`/key-types/${params.id}`, params)
export const typeDestroy = (params = '') => Http.delete(`/key-types/${params.id}`)

// LIST ALL Keys Status
export const statusList = (params = '') => Http.get(`/key-status${serialize(params)}`)
export const statusCreate = (params = '') => Http.post('/key-status', params)
export const statusByID = (params = '') => Http.get(`/key-status/${params}`)
export const statusUpdate = (params = '') => Http.put(`/key-status/${params.id}`, params)
export const statusDestroy = (params = '') => Http.delete(`/key-status/${params.id}`)

//LIST OF ALL Keys Suppliers
export const suppliersList = (
	params = {
		per_page: 'all',
		page: 1,
	},
) => Http.get(`/key-suppliers${serialize(params)}`)
export const supplierCreate = (params = '') => Http.post('/key-suppliers', params)
export const supplierByID = (params = '') => Http.get(`/key-suppliers/${params}`)
export const supplierUpdate = (params = '') => Http.put(`/key-suppliers/${params.id}`, params)
export const supplierDestroy = (params = '') => Http.delete(`/key-suppliers/${params.id}`)

//LIST OF ALL Keys Suppliers Prs
export const prsList = (params = '') => Http.get(`/key-suppliers-prs${serialize(params)}`)
export const prsCreate = (params = '') => Http.post('/key-suppliers-prs', params)
export const prsByID = (params = '') => Http.get(`/key-suppliers-prs/${params}`)
export const prsUpdate = (params = '') => Http.put(`/key-suppliers-prs/${params.id}`, params)
export const prsDestroy = (params = '') => Http.delete(`/key-suppliers-prs/${params.id}`)

// KEYs
export const keyList = (params = '') => Http.get(`/keys${serialize(params)}`)
export const newKeys = (params = '') => Http.post('/keys', params)
export const keysByID = (params = '') => Http.get(`/keys/${params}`)
export const updateKeys = (params = '') => Http.put(`/keys/${params.id}`, params)
export const deleteKeysByID = (params = '') => Http.delete(`/keys/${params.id}`)

//LIST OF ALL Keys Items
export const itemsList = (params = '') => Http.get(`/key-items${serialize(params)}`)
export const itemCreate = (params = '') => Http.post('/key-items', params)
export const itemByID = (params = '') => Http.get(`/key-items/${params}`)
export const itemUpdate = (params = '') => Http.put(`/key-items/${params.id}`, params)
export const itemDestroy = (params = '') => Http.delete(`/key-items/${params.id}`)

//LIST OF ALL Keys Contents Types
export const contentsList = (params = '') => Http.get(`/content-types${serialize(params)}`)
export const contentCreate = (params = '') => Http.post('/content-types', params)
export const contentByID = (params = '') => Http.get(`/content-types/${params}`)
export const contentUpdate = (params = '') => Http.put(`/content-types/${params.id}`, params)
export const contentDestroy = (params = '') => Http.delete(`/content-types/${params.id}`)
