const token = [
	{
		path: '/token/dashboard',
		name: 'token-dashboard',
		component: () => import('@/views/token/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/token/settings',
		name: 'token-settings',
		component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/token/messages',
		name: 'token-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/token/newsletter',
		name: 'token-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/token/notifications',
		name: 'token-notifications-list',
		component: () => import('@/views/notifications/Notifications.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default token
