const keys = [
	{
		path: '/agency/keys/types/list',
		name: 'agency-keys-types-list',
		component: () => import('@/views/agency/keys/keys-types/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/keys/content-type/list',
		name: 'agency-keys-contents-list',
		component: () => import('@/views/agency/keys/keys-contents/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/keys/status/list',
		name: 'agency-keys-status-list',
		component: () => import('@/views/agency/keys/keys-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/keys/suppliers/list',
		name: 'agency-keys-suppliers-list',
		component: () => import('@/views/agency/keys/keys-suppliers/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/keys/suppliers-prs/list',
		name: 'agency-keys-suppliers-prs-list',
		component: () => import('@/views/agency/keys/keys-suppliers-prs/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/keys/keys/list',
		name: 'agency-keys-list',
		component: () => import('@/views/agency/keys/keys/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/keys/keys-items/:id',
		name: 'agency-keys-items-list',
		component: () =>
			import(/* webpackChunkName: "ApisListCredentials" */ '@//views/agency/keys/keys-items/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default keys
