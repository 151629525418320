import {
	deleteScheduledNotification,
	getScheduledNotifications,
	notificationCreate,
} from '@/services/agency/notifications.service'

export default {
	namespaced: true,
	state: {
		notificationsSaveStatus: 'init',

		notificationScheduledList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		notificationScheduledStatus: 'pending',
		deleteScheduledNotificationStatus: 'init',
	},
	getters: {
		getNotificationsSaveStatusAgency(state) {
			return state.notificationsSaveStatus
		},

		getScheduledNotificationList(state) {
			return state.notificationScheduledList
		},
		getScheduledNotificationStatus(state) {
			return state.notificationScheduledStatus
		},
		deleteScheduledNotificationStatus(state) {
			return state.deleteScheduledNotificationStatus
		},
	},
	mutations: {
		NOTIFICATION_SAVE_STATUS(state, value) {
			state.notificationsSaveStatus = value
		},

		SET_SCHEDULED_NOTIFICATION_LIST(state, value) {
			state.notificationScheduledList = value
		},
		SET_SCHEDULED_NOTIFICATION_STATUS(state, value) {
			state.notificationScheduledStatus = value
		},
		SET_DELETE_SCHEDULED_NOTIFICATION_STATUS(state, val) {
			state.deleteScheduledNotificationStatus = val
		},
	},
	actions: {
		async saveNewNotificationAgency({ commit }, params) {
			commit('NOTIFICATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await notificationCreate(params)
				commit('NOTIFICATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NOTIFICATION_SAVE_STATUS', 'init')
				throw error
			}
		},

		async deleteScheduledNotificationByID({ commit }, params) {
			commit('SET_DELETE_SCHEDULED_NOTIFICATION_STATUS', 'pending')
			try {
				const { data } = await deleteScheduledNotification(params)
				commit('SET_DELETE_SCHEDULED_NOTIFICATION_STATUS', 'success')
				return data
			} catch (error) {
				commit('SET_DELETE_SCHEDULED_NOTIFICATION_STATUS', 'error')
				throw error
			}
		},

		async fetchScheduledNotifications({ commit }, params) {
			commit('SET_SCHEDULED_NOTIFICATION_LIST', [])
			commit('SET_SCHEDULED_NOTIFICATION_STATUS', 'pending')
			try {
				const { data } = await getScheduledNotifications(params)
				commit('SET_SCHEDULED_NOTIFICATION_STATUS', 'init')
				commit('SET_SCHEDULED_NOTIFICATION_LIST', data)
				return data
			} catch (error) {
				commit('SET_SCHEDULED_NOTIFICATION_STATUS', 'init')
				throw error
			}
		},
	},
}
