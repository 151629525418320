import {
	deleteNotification,
	getAllNotifications,
	getNotifications,
	notificationByID,
	notificationCreate,
} from '@/services/notifications.service'

export default {
	namespaced: true,
	state: {
		notificationList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		notificationStatus: 'pending',
		notification: {},
		notificationsSaveStatus: 'init',
		deleteNotificationStatus: 'init',

		notificationAllList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		notificationAllStatus: 'pending',
	},
	getters: {
		notifications(state) {
			return state.notifications
		},
		getNotificationList(state) {
			return state.notificationList
		},
		getNotificationStatus(state) {
			return state.notificationStatus
		},
		getNotificationsSaveStatus(state) {
			return state.notificationsSaveStatus
		},
		deleteNotificationStatus(state) {
			return state.deleteNotificationStatus
		},

		getAllNotificationList(state) {
			return state.notificationAllList
		},
		getAllNotificationStatus(state) {
			return state.notificationAllStatus
		},
	},
	mutations: {
		SET_NOTIFICATIONS(state, val) {
			state.notifications = val
		},
		SET_NOTIFICATION_LIST(state, value) {
			state.notificationList = value
		},
		SET_NOTIFICATION_STATUS(state, value) {
			state.notificationStatus = value
		},
		NOTIFICATION_SAVE_STATUS(state, value) {
			state.notificationsSaveStatus = value
		},
		SET_DELETE_NOTIFICATION_STATUS(state, val) {
			state.deleteNotificationStatus = val
		},

		SET_ALL_NOTIFICATION_LIST(state, value) {
			state.notificationAllList = value
		},
		SET_ALL_NOTIFICATION_STATUS(state, value) {
			state.notificationAllStatus = value
		},
	},
	actions: {
		async fetchNotifications({ commit }, params) {
			commit('SET_NOTIFICATION_LIST', [])
			commit('SET_NOTIFICATION_STATUS', 'pending')
			try {
				const { data } = await getNotifications(params)
				commit('SET_NOTIFICATION_STATUS', 'init')
				commit('SET_NOTIFICATION_LIST', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STATUS', 'init')
				throw error
			}
		},
		async fetchNotificationByID({ commit }, params) {
			commit('NOTIFICATIONS_STATUS', 'pending')
			try {
				const { data } = await notificationByID(params)
				commit('NOTIFICATIONS_STATUS', 'init')
				commit('SET_NOTIFICATION', data.data)
				return data
			} catch (error) {
				commit('NOTIFICATIONS_STATUS', 'init')

				return error
			}
		},
		async saveNewNotification({ commit }, params) {
			commit('NOTIFICATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await notificationCreate(params)
				commit('NOTIFICATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NOTIFICATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteNotificationByID({ commit }, params) {
			commit('SET_DELETE_NOTIFICATION_STATUS', 'pending')
			try {
				const { data } = await deleteNotification(params)
				commit('SET_DELETE_NOTIFICATION_STATUS', 'success')
				return data
			} catch (error) {
				commit('SET_DELETE_NOTIFICATION_STATUS', 'error')
				throw error
			}
		},

		async fetchAllNotifications({ commit }, params) {
			commit('SET_NOTIFICATION_LIST', [])
			commit('SET_NOTIFICATION_STATUS', 'pending')
			try {
				const { data } = await getAllNotifications(params)
				commit('SET_ALL_NOTIFICATION_STATUS', 'init')
				commit('SET_ALL_NOTIFICATION_LIST', data)
				return data
			} catch (error) {
				commit('SET_ALL_NOTIFICATION_STATUS', 'init')
				throw error
			}
		},
	},
}
