import {
	mdiBellOutline,
	mdiClipboardAccount,
	mdiHome,
	mdiMessage,
	mdiNewspaperVariantMultipleOutline,
	mdiStore,
} from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'club-dashboard',
	},
	{
		icon: mdiStore,
		title: 'menu.club.franchiseModels',
		to: 'club-franchise-models-list',
	},
	{
		icon: mdiClipboardAccount,
		title: 'menu.club.contactFranchise',
		to: 'club-contact-franchise-list',
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'club-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'club-newsletter-list',
	},
	{
		icon: mdiBellOutline,
		title: 'notifications.notifications',
		to: 'club-notifications-list',
	},
]
