const application = [
	{
		path: '/widget-format/list',
		name: 'jobs-widget-format-list',
		component: () => import(/* webpackChunkName: "applicationMethodList" */ '@/views/jobs/widget-format/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/widgets/list',
		name: 'jobs-widgets-list',
		component: () => import(/* webpackChunkName: "applicationStatusList" */ '@/views/jobs/widgets/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default application
